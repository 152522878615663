@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

html {
  overflow: hidden;
}
.htmlOverFlow{
  overflow: auto;
}
body {
  background-color: #f7f7f7;
  text-transform: lowercase;
  font-family: "GothamBold", Arial, sans-serif;
}

// calendar icon for date fields
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  display: block;
  background: url(/assets/icons/calendar.svg) no-repeat center center;
}

//case-status text common styles
@mixin case-status-text {
  padding: 2px 16px;
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0.01px;
  color: #ffffff;
  opacity: 1;
  // font-size: 23px;
}

.ag-theme-nglo {
    --ag-header-foreground-color: #000000;
    --ag-header-background-color: #FFFFFF;
    --ag-font-size: 14px;
  
    .grid-row-even {
      background-color: #F7F7F7;
    }
    .ag-header-cell {
      border: 0.5px solid #DBDCDD;
    }
    
    .ag-header-cell-label {
      flex-direction: column;
    }
    .ag-header-row  {
      text-transform: lowercase;
    }
    .ag-cell-value {
      text-align: center;
      font-size: 14px;
      text-transform: lowercase;
      height: 40px;
    }
    .ag-row-selected{
      .ag-cell-value{
        height: inherit;
      }
    }
    .custom-header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .custom-header-menu {
      align-self: flex-end;
    }
    .custom-header-label {
      margin-left: 4px;
      margin-right: 4px;
    }
    .highlight-updated-value {
      background-color:#ffe594;
    }
    .highlight-load-discharge-mismatch {
      // background-color: #f57777;
      background-color:#f2bcbc;
    }
    .highlight-port-not-found {
      background-color:#f2bcbc;
    }
    .highlight-error {
      background-color:#f2bcbc !important;

    }
    .highlight-product-mismatch {
      background-color:#f2bcbc;
    }
    .highlight-empty-required-col{
      background-color:#f2bcbc;
    }
    .disabled{
      pointer-events: none;
      color: #6c757d !important;
    }

  .grid-row-even {
    background-color: #f7f7f7;
  }
  .ag-header-cell {
    border: 0.5px solid #dbdcdd;
  }

  .ag-header-cell-label {
    flex-direction: column;
  }
  .ag-header-row {
    text-transform: lowercase;
  }
  .ag-cell-value {
    text-align: center;
    font-size: 14px;
    text-transform: lowercase;
  }
  .custom-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .custom-header-menu {
    align-self: flex-end;
  }
  .custom-header-label {
    margin-left: 4px;
    margin-right: 4px;
  }
  .highlight-updated-value {
    background-color: #68a8e3;
  }
  .highlight-load-discharge-mismatch {
    // background-color: #f57777;
    background-color:#f2bcbc;
  }
  .highlight-port-not-found {
    background-color:#f2bcbc;
  }
  .highlight-product-mismatch {
    background-color:#f2bcbc;
  }
  .highlight-empty-required-col{
    background-color:#f2bcbc;
  }
  .disabled {
    pointer-events: none;
    color: #6c757d !important;
  }

  //case-status classes in ag-grid
  .grid-case-status {
    &-pending span {
      background: #e5601f;
      font-weight: bold;
      @include case-status-text;
    }
    &-invalid span {
      background: #61002a;
      font-weight: bold;
      @include case-status-text;
    }
    &-running span {
      background: #4f4f4f;
      font-weight: bold;
      @include case-status-text;
    }
    &-optimized span {
      background: #248321;
      font-weight: bold;
      @include case-status-text;
    }
    &-failed span {
      background: #bc0c32;
      font-weight: bold;
      @include case-status-text;
    }
    &-warning span {
      background: #ffce06;
      font-weight: bold;
      @include case-status-text;
    }
    &-not-found span {
      background: #000000;
      font-weight: bold;
      @include case-status-text;
    }
  }
  .ag-checkbox-input-wrapper::after {
    color: #0066b2;
  }
  .collapse-icon-collapsed {
    background: url(./assets/icons/plus.svg) no-repeat center center;
    border: 0;
    outline: none;
    cursor: pointer;

   }
   .collapse-icon-expanded {
    background: url(./assets/icons/minus.svg) no-repeat center center;
    border: 0;
    outline: none;
    cursor: pointer;
   }
   .collapsible-section {
    .collapse-header {
      display: flex;
      justify-content: space-between;
      .content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-height: 40px;
      }
    }
    .collapse-content {
      .description {
        resize: none;
        width: 100%;
        letter-spacing: 0.49px;
        line-height: 1.5;
        padding: 5px;
        height: 130px;
      }
      button {
        margin-top: -6px !important;
      }
    }
  }
  .ag-header-cell, .ag-header-group-cell{
    padding-left: 5px;
    padding-right: 5px;
  }
}
.tabbed {
  margin-bottom: 0 !important;
}

.not-editable {
  cursor: not-allowed;
  pointer-events: none;
}
.buttons button {
  margin: 0px !important;
  margin-right: 0.5rem !important;
}
.disabled {
  pointer-events: none;
  color: #6c757d !important;
}
// Button wrapper
.btn-wrap {
  & > .button {
    margin: 1rem 0.25rem 0 !important;
  }
  & > :first-child {
    margin-left: 0 !important;
  }
  & > :last-child {
    margin-right: 0 !important;
  }
}

// Dropdown
.dropdown > button {
  line-height: 26px;
  &:after {
    top: 18px;
  }
}

.landing-page{
  .select-container{
    .select-wrap{
      ul.aria-expanded{
        box-shadow: 0px 4px 5px #00000029;
    }
    }
  }
}

// Form Control
.form-control {
  border-radius: 0px;
  line-height: 34px;
  font-size: 14px;
}
.form-group{
  .label {
    letter-spacing: 0.02px;
    color: #6B6D6F;  
  }
  
}

.input-error {
  border: 1px solid red;
}
.error-block {
  color: red;
  line-height: 2.125;
}
.form-validation-msg {
  color: #97002e;
  text-transform: lowercase;
  display: block;
  margin-top: 5px;
}
.form-validation-border {
  border: 1px solid #97002e !important;
}
.form-validation-label {
  color: #97002e !important;
}

// nSelect dropdown style
.dropdown:not(.auto-size) > ul[role="listbox"] {
  max-height: 300px;
  overflow-y: auto;
  justify-content: flex-start;
}

// case-log filter-panel dropdown
.panel-content {
  .dropdown {
    width: 299px;
  }
  .dropdown:not(.auto-size) > ul[role="listbox"] {
    max-height: 145px;
  }
}

//case-name class in case-log page
.case-name {
  text-align: left;
  letter-spacing: 0.02px;
  color: #0066b2;
  opacity: 1;
  font-weight: bold;
}
//case-status classes in normal templates
.case-status {
  &-pending {
    background: #e5601f;
    @include case-status-text;
  }
  &-invalid {
    background: #61002a;
    @include case-status-text;
  }
  &-running {
    background: #4f4f4f;
    @include case-status-text;
  }
  &-optimized {
    background: #248321;
    @include case-status-text;
  }
  &-failed {
    background: #bc0c32;
    @include case-status-text;
  }
  &-warning {
    background: #ffce06;
    @include case-status-text;
  }
  &-not-found {
    background: #000000;
    @include case-status-text;
  }
}

.my-div-greenIcon{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: 40%;
  left: 50%;
  margin-left: 115px;
  border-radius: 50%;
  border: 10px solid #00ff00;
}
.my-div-greenIcon::after {
  position: absolute;
  content: '';
  width: 2px;
  height: 2px;
  left: -10px;
  top: 4px;
  border: 10px solid transparent;
  border-top: 8px solid #00ff00;
}
.my-div-orangeIcon {
  height: 20px ;
  width: 20px ;
  border-radius: 50%;
  top: 40%;
  left: 50%;
  margin-left: 115px;
  border-radius: 50%;
  border: 10px solid #FFA500;
}
.my-div-orangeIcon::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  left: -10px;
  top: 4px;
  border: 10px solid transparent;
  border-top: 8px solid #FFA500;
}
// .my-div-orangeIcon{
//   height: 25px !important;
//   width: 25px !important;
//   background-color: #FFA500;
//   border-radius: 50%;
//   display: inline-block;
// }
// To remove arrows from input type=number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

nav#site-specific-nav{
  ul:not(.in-mega) {
      li { 
          a{
          padding: 14px 40px;
          line-height: 16px;
      }
      }
  } 
}
.sub-header{
  .dropdown{
      button{
          padding: 6px 32px 6px 10px !important;
          &::after{
            top: 14px;
          }
      }
}
}

.ag-header-cell:not(.ag-header-cell-auto-height){
  .ag-header-cell-comp-wrapper{
    justify-content: center;
  }
}

div.ag-cell{
  &[col-id='caseName']{
     span.ag-cell-wrapper{
      span.ag-group-value{
        &:hover{
          text-decoration: underline;
          cursor: pointer;
        }
      }
      span.ag-group-contracted,.ag-group-expanded {
        position: absolute;
        right: -18px;
    }
     }
  }
}
.ag-header-group-cell-label{
  justify-content: center;
}
.flatView{
  .ag-pinned-left-cols-container{
    border-right:3px solid #0085B8 !important;
  }
     .ag-header-cell{
      .icon-arrow-up{
          &::before{
            color: #ffffff;
          }
        }
        .icon-arrow-down{
         &::before{
            color:#ffffff;
          }
        }
     }
  
  .customSortUpLabel{
    &.active{
      .icon-arrow-up{
        &::before{
          color: #FFA500 !important;
        }
      }
    }
  }

  .customSortDownLabel{
    &.active{
      .icon-arrow-down{
        &::before{
          color: #FFA500 !important;
        }
      }
    }
  }

  .ag-header-cell{
    &[col-id="2"],&[col-id="1"]{
      background: #003653;
      color: #ffffff;
      &:hover{
        color: #003653;
      }
     }
    &:hover{
      .icon-arrow-up{
     &::before{
       color:#0066B2 !important;
     }
   }
   .icon-arrow-down{
    &::before{
       color:#0066B2 !important;
     }
   }
  }

   .icon-arrow-up:hover{
    &::before{
      color:#0066b2 !important;
    }
  }
  .icon-arrow-down:hover{
   &::before{
      color:#0066b2 !important;
    }
  }
   
  }
  [col-id*="groupingId"],[col-id="source"]{
    &.ag-header-cell{
      &:hover{
        color: #003653;
      }
      background-color:#003653;
      color: #fff;
    }
  }
    [col-id*="loadStrategy"],
    [col-id*="loadPort"],
    [col-id*="loadMonth"],
    [col-id*="loadWindowFrom"],
    [col-id*="loadWindowTo"],
    [col-id*="loadCurveName"],
    [col-id*="loadCurvePrice"] {
      &.ag-header-cell {
        &:hover{
          color: #005370;
        }
        background: #005370;
        border-right: 1px solid #DBDCDD;
        color: #fff;

      }
    }
  
    [col-id*="dischargeStrategy"],
    [col-id*="dischargePort"],
    [col-id*="dischargeMonth"],
    [col-id*="dischargeWindowFrom"],
    [col-id*="dischargeWindowTo"],
    [col-id*="dischargeCurveName"],
    [col-id*="dischargeCurvePrice"]
    {
      &.ag-header-cell {
        &:hover{
          color: #00708C;
        }
        background: #00708C;
        border-right: 1px solid #DBDCDD;
        color: #fff;
      }
    }

    [col-id*="loadQty"],
    [col-id*="dischargeQty"]
    {
      &.ag-header-cell {
        &:hover{
          color: #0091A4;
        }
        background: #0091A4;
        border-right: 1px solid #DBDCDD;
        color: #fff;
      }
    }

}
.ag-pinned-left-cols-container{
  border-right: var(--ag-borders-critical) var(--ag-border-color);
}
.header-0,.header-1,.header-2, .header-3{
  color: #fff;

}
.header-0{
  background-color:#003653 !important;
}
.header-1{
  background-color:#005370 !important;
}
.header-2{
  background-color:#00708C !important;
}
.header-3{
  background-color:#0091A4 !important;
}
button.primary:disabled {
  cursor: not-allowed;
}

button.secondary:disabled {
  cursor: default !important;
}

.ag-cell-wrapper.ag-row-group{
  .ag-group-expanded{
    .ag-icon-tree-open::before{
      color: #0066B2;
    }
  }
}

.ag-header-cell-filtered{
  .custom-header-label:after{
    content: "•";
    font-size: 2rem;
    color: orange;
    display: inline-block;
    height: 1rem;
    vertical-align: text-top;
    position: relative;
    top: -25px;
  }
}
.custom-label:after{
  content: "•";
  font-size: 2rem;
  color: orange;
  display: inline-block;
  height: 1rem;
  vertical-align: text-top;
  position: relative;
  top: -25px;
}
[col-id='caseName']{
  .ag-cell-wrapper{
    .ag-group-value::after{
    content: " ";
    background-image: url("./assets/icons/16_Chevron_Right.svg");
    background-size: 23px 29px;
    height: 20px;
    width: 20px;
    position: absolute;
    top: 6px;
    left: calc(100% - 15px);
    }
    .ag-group-contracted{
      .ag-icon-tree-closed::before{
      content: " ";
      display: inline-flex;
      background-image: url("./assets/icons/plus.svg");
      background-size: 17px 22px;
      height: 20px;
      width: 20px;
      }
      .ag-icon-tree-closed:hover{
        &::before{
          background-image: url("./assets/icons/plus-blue.svg");
        }
      }
    }
    .ag-group-expanded{
      .ag-icon-tree-open::before{
        content: " ";
        display: inline-flex;
        background-image: url("./assets/icons/minus.svg");
        background-size: 17px 22px;
        height: 20px;
        width: 20px;
      }
    }
  }
}

.case-name.running{
  .ag-cell-wrapper{
    .ag-group-value::after{
       background-image: url("./assets/icons/16_Chevron_Right-grey.svg");
    }
  }
}
.listbox-wrapper {
  .select-multiple {
    max-height: 138px;
    overflow-y: auto;
    justify-content: start;
  }
}

span.port-count {
  color: #fff;
  background: #0066b2;
  padding: 5px 8px;
  border-radius: 50px;
  margin-left: 3px;
}
// loader icon in button
.loader-button {
  margin-left: 8px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: relative;
  animation: rotateButton 1s linear infinite;
  z-index: 1;
}
.loader-button::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 4px solid #0b2d71;
  animation: prixClipFixButton 2s linear infinite;
}

@keyframes rotateButton {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFixButton {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.ag-header-cell {
  &.select-all-checkbox{
    padding-left: 15px;
  }
}

.custom-tooltip p {
  margin: 5px;
  font-size: 14px;
}
.custom-tooltip p span:first-child {
  font-weight: bold;
}
.custom-tooltip{
  background-color: #ffffff;
  color:#000000;
  border:1px solid black;
  // max-width: 250px;
  pointer-events: none;
  transition: opacity 1s;
}